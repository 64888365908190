<template>
  <app-container v-if="!isTextLoading" colWidth="col-12" icon="file_save">
    <template #back>
      <app-back
          :routeName="texts.back_link.route"
          :text="texts.back_link.text"
          colWidth="col-12"
          pp-back
      />
    </template>
    <template #header>
      <h1 class="mb-4 text-dark">{{ texts.title }}</h1>
    </template>
    <template #body>
      <div class="row">
        <table class="reports-table">
          <thead>
          <tr>
            <th v-for="header in texts.table_headers" :key="header">
              {{ header }}
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in reports" :key="item.id">
            <td>{{ item.id }}</td>
            <td>{{ item.project_name }}</td>
            <td>{{ item.calculation_name }}</td>
            <td>{{ item.product_family_label }}</td>
            <td>{{ item.created_at }}</td>
            <td v-for="exportType in texts.exports" :key="exportType.type">
              <img
                  :src="require(`@/assets/img/${exportType.img}`)"
                  class="cursor-pointer"
                  @click="handleExport(exportType.type, item)"
              />
            </td>
            <td class="icon-td">
              <i class="bi bi-info-circle-fill" @click="getInfo(item)"></i>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="row pagination-row">
        <div class="col-12 pagination-row__col">
          <app-pagination
              :current-page="currentPage"
              :page-size="limit"
              :total="total"
              @page-change="handlePageChange"
          />
        </div>
      </div>
    </template>
  </app-container>
  <!-- Info modal -->
  <el-dialog
      v-model="showModal"
      :title="selectedItem.title"
      destroy-on-close
      width="500"
  >
    <div class="row">
      <div class="col-12">
        <p v-for="param in selectedItem.params" :key="param.key">
          <span class="text-bold">{{ param.label }} : </span>{{ param.value }}
        </p>
      </div>
    </div>
    <template #footer>
      <div class="dialog-footer btn-end">
        <el-button type="primary" @click="showModal = false"> OK</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>

import Database from '@/utils/services/Database';
import Configurator from '@/utils/services/Configurator';
import useTexts from '@/mixins/useTexts';
import Download from "@/utils/services/Download";

export default {
  name: 'UserReports',
  mixins: [useTexts],
  data() {
    return {
      page: 'user_reports',
      filter: '',
      reports: [],
      currentPage: 1,
      total: 2,
      limit: 10,
      selectedItem: {},
      isLoading: false,
      showModal: false,
    };
  },
  async created() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.isLoading = true;
      const res = await Database.getAll('reports', {
        limit: this.limit,
        offset: this.currentPage * this.limit - this.limit,
      });

      this.reports = res.reports;
      this.total = res.total;

      this.isLoading = false;
    },
    async handlePageChange(val) {
      this.currentPage = val;
      await this.getData();
    },
    async getInfo(item) {
      console.log(item)
      const params = await Configurator.getParams(item, {
        key: item.product_family_key,
        id: item.product_family_id,
        label: item.product_family_label
      });
      this.selectedItem = {
        title: `FDES n°${item.id}`,
        params: params,
      };
      this.showModal = true;
    },
    async handleExport(type, item) {
      const {data} = await Database.export({file_type: type, report_id: item.id});

      await Download.downloadFile(data)
    }
  },
};
</script>

<style lang="scss">
.reports-table {
  border-collapse: collapse;

  thead {
    tr {
      th {
        padding: 0.75rem;
        background-color: $dark;
        color: $light;
        font-weight: bold;

        &:first-child {
          border-radius: 1rem 0 0 0;
        }

        &:last-child {
          border-radius: 0 1rem 0 0;
        }
      }
    }
  }

  tbody {
    tr {
      border: 1px solid $light-grey;

      td {
        padding: 0.5rem;

        img {
          height: 2.5rem;
        }

        &.icon-td {
          text-align: center;

          i {
            cursor: pointer;
            transition: $trs-default;
            font-size: 1.5rem;
            color: $secondary;

            &:hover {
              color: $primary;
            }
          }
        }
      }
    }
  }
}

.pagination-row {
  &__col {
    display: flex;
    justify-content: flex-end;
    padding: 2rem 1rem;

    li {
      cursor: pointer !important;
    }
  }
}
</style>
